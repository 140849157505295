import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import HeartSpinner3 from './SpinnerHeart/HeartSpinner3';

export default function LoadingButtonsTransition({ onClick, loading,buttonLabel,style }) {
  const handleClick = () => {
    if (!loading) {
      // Call the onClick function passed from props only if not loading
      onClick();
    }
  };
  const [delayedText, setDelayedText] = useState(false);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setDelayedText(true);
      }, 10000); // 10 seconds
      return () => clearTimeout(timer); // Cleanup timer on unmount
    } else {
      setDelayedText(false);
    }
  }, [loading]);


  return (
    <Button
      variant="contained"
      style={{
        marginTop: '10px',
        fontFamily: '"Secular One", sans-serif',
        marginBottom:'30px',
        ...style
      }}
      disabled={loading}
      onClick={handleClick}
      startIcon={
        loading ? (
          // startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
      // startIcon={loading ? <HeartSpinner2 size={5}  />  : <SendIcon />}
          <HeartSpinner3  /> // Adjust vertical position of icon
        ) : (
          <SendIcon style={{ transform: 'rotate(180deg)' }} />
        )
      }
    >
      <span style={{ marginTop: loading ? '2px' : '0px' }}>
        {loading
          ? delayedText
            ? '...זה לוקח יותר זמן מהצפוי'
            : '...עובדים על זה'
          : buttonLabel}
      </span>
    </Button>
  );
};
