import React, { useState } from 'react';


function Terms() {

  
  return (
    <>

    <div className="custom-home-page">
      <div className="hero">
        <div className="cool-move">
        <div style={{direction:"rtl"}} > 
<h1 style={{fontSize:"larger"}}>תנאי שימוש ב Winkee
<br></br>
  להלן:
  <br></br>
  " ווינקי " 
  </h1>
  
  
<h2>
ברוך הבא – שמחים שהגעת דע כי על ידי גישה או שימוש באתר ובשירותים של ווינקי, אתה מסכים להיות מחויב להסכם תנאי שימוש זה ("תנאי השימוש"), לרבות מדיניות הפרטיות שלנו ולכן חשוב שתקרא בעיון את תנאים
אלו בעיון לפני שאתה מתחיל לקבל את השירותים שלנו.
</h2>
<p>
<strong>--</strong> על ידי כניסתך, התחברותך, שימושך באתר ו/או בשירות ווינקי, הנך מאשר
כי קראת והבנת את תנאי השימוש הבאים וכי התנאים יחייבו אותך וכי תפעל בהתאם להם, לרבות התנאים המפורטים במדיניות הפרטיות ואבטחת המידע של ווינקי, אותם תוכל למצוא כאן בהמשך להלן: תנאי שימוש אלו ומדיניות הפרטיות ואבטחת המידע שלנו יכונו יחדיו: "התנאים" או "תנאי
השימוש"
<p>
<strong>--</strong> אם אינך מסכים לאי אלו מתנאי השימוש המפורטים להלן, הנך מחויב, באופן
מידי, להימנע מכניסה, מהתחברות או משימוש בכל אופן באתר ו/או בשירות ווינקי.</p>
<p>
<strong>--</strong> במידה ויש לך שאלות נוספות או הערות בנוגע לתנאים אלו, הנך מוזמן לפנות אלינו באמצעות הודעת דואר אלקטרוני לכתובת winkee.dating@gmail.com.

<p>
אנו
עומדים לרשותך לצורך כל בקשה, תגובה, שאלה או תלונה. 
</p>
<p>
<strong>--</strong> תנאי השימוש ומדיניות הפרטיות מנוסחים בלשון זכר אך מתייחסים כמובן לשני
המינים.
</p>

<h2>
הקדמה 
</h2>
<p>
<strong>--</strong> השירותים של ווינקי (להלן: "ווינקי"), מאפשרים לך לקבל המלצות
להודעת צ'אט ממערכת ai (איטיליגנציה מלאכותית של חברות : google,openai בהתקשרות חיצונית chatgpt, gemini)בצורה דיגיטלית על מנת לסייע לך בסיטואציות חברתיות בעולם הדייטינג, ובכך לעזור לך ליצור קשרים בחייך
האישיים, החברתיים והרומנטיים. 
</p>

<p>
<strong>--</strong> תנאי שימוש אלה ואופן התקשרות איתנו חלים על המבקרים באתר שלנו,
winkee.dating )להלן: "האתר"(, והעושים שימוש בשירות הודעות הדייטינג האוטומטיות )להלן: "השירות"(, הניתן באמצעות צ'אט והעלאת תמונה מצילום מסך באתר וגם שירות ה׳קהילה׳ אשר נותן אפשרות להעלות תצלום מסך של שיחה פרטית בצורה אנונימית מבלי לחשוף את זהות מעלה התמונה ואת זהות האדם המגיב לו )כלל השירותים, ביחד, יכונו להלן: ״שירות ווינקי או
״השירותים״(.
😊
</p>


<p>
<strong>--</strong> האתר - האתר משמש לשם הרשמה, מתן שירות הצ'אט, קהילה, מתן מידע כללי לגבינו ולגבי השירותים הנוספים וכן ליצירת קשר עם ווינקי.
</p>

<p>


<strong>--</strong> ווינקי – הצעת הודעות הדייטינג- השירות ניתן באמצעות העברת
תוכן השיחה של מעלה תצלום המסך בעזרת api לחברת google ו- openai . הודעות הצאט יישלחו לחשבון ווינקי שלך באתר, דרך המכשיר ממנו התחברת לאיזורך האישי.

</p>
<p>
<strong>--</strong> שים לב: זוהי אחריותך הבלעדית להכנס לחשבונך, במכשיר ממנו אתה מעוניין לגשת לשירות הצ'אט. למען הסר ספק, שימושך בווינקי כפוף לתנאי
השימוש ומדיניות הפרטיות החלים על משתמשי ווינקי. 
</p>
<p>
<strong>--</strong> ווינקי מצהירה ומתחייבת כי בעת שימושך בשירות ווינקי, יוצג בפניך
תשובות של מערכת בינה מלאכותית דיגיטלית אשר יתנו לך רעיונות להתקשרות בין-אישית עם אנשים. בינה מלאכותית של מערכת חיצונית תשלח לך הצעות לתשובה על סמך השיחה ששלחת לה ותענה לך באתר בהתאם לסיטואציה שאתה תציג לה בשיחה שהעלת בתצלום המסך, ויסייע לך באופן אישי ומתאים לסיטואציה )להלן: "ai"(. אם הנך סבור כי תשובות הai שהוצגו לך כחלק משימושך בשירות הצאט או כל שירות אחר, אינו מדויק או אם הנך סבור כי נעשה שימוש זדוני במידע או בנתונים אודותיך השמורים במאגרי המידע של ווינקי, אנא פנה אלינו בהקדם האפשרי באמצעות שליחת דואר-אלקטרוני לכתובת winkee.dating@gmail.com עם פרטים מספקים בדבר
האירוע, ואנו ננקוט בכל האמצעים העומדים לרשותנו על מנת לסייע לך.

</p>
<p>
<strong>--</strong> זמינות: אנו עושים מאמצים על מנת להבטיח שהשירותים שלנו יהיו זמינים
עבורך באופן רציף. אנחנו משתדלים להיות זמינים 24/7 ולענות מיד עם קבלת ההודעה במייל. יחד עם זאת, למען הבהירות, אנו מתחייבים להשיב לא יאוחר מ48 שעות ממועד הפניה, ככל שהפניה תגיע אלינו במהלך השעות 9:00 בבוקר
עד 24:00 )להלן: "שעות הפעילות"(. 
</p>

<p>
<strong>--</strong> אנו עושים מאמצים סבירים על מנת להבטיח שהשירותים שלנו יהיו זמינים לך
באופן רציף. ווינקי אינה אחראית או מתחייבת כי השירותים יפעלו או יהיו זמינים בכל עת ללא הפרעות וללא פגם, למשל עקב הפסקות בחיבור האינטרנט שלך והפסקות בפעילות החומרה או התוכנה בשל בעיות טכניות או אחרות שאינן בשליטת ווינקי. ווינקי ו/או נציגי החברה המורשים לא יהיו אחראים לכל תוצאה הנובעת מתקלות טכניות )לרבות אך לא רק בקשר לקישוריות האינטרנט, עומס קו או שרתים, עיכובים והפרעות קשורים( והנובעות מספקי אינטרנט וטלקומוניקציה. וגם לא יהיו אחראים לתשובות שתקבל מהai
החיצוני שעושה שימוש במידע שלך. 
</p>
<p>
<strong>--</strong> ווינקי ו/או נציגי החברה, אינם מתחייבים )א( כי שירות ווינקי ו/או האתר
ו/או התכנים הנם או יהיו בטוחים לשימוש, מדויקים, מלאים, ללא תקלות או הפרעות, או נקיים מווירוסים, פגמים, תולעים, רכיבים מזיקים אחרים או הגבלות

תוכנה אחרות, )ב( כי הם יתקנו כל שגיאות, תקלות או פגמים בשירות ווינקי ו/או האתר התכנים, )ג( כי השימוש, אי יכולת להשתמש או לתפעל, או תוצאות השימוש בשירות ווינקי ו/או האתר ו/או התכנים, לרבות תוצאות השימוש בשירות ווינקי ו/או באתר ו/או בתכנים יעמדו בדרישות המשתמש או
ציפיותיו.

</p>

<p>
<strong>--</strong> אנונימיות בצ'אט- הפרטיות והשמירה על הפרטים האישיים של משתמשי
ווינקי חשובה לנו ואנו מבינים כי לא כל משתמשי ווינקי יהיו מעוניינים לחשוף את פרטיהם. יחד עם זאת, כדי לעשות שימוש בשירות הצ'אט והקהילה, עליכם למסור פרטים לטובת פתיחת חשבון בווינקי. האדם שעמו אתם תנהלו את הצ'אט, לא יידע את הפרטים האישיים שלכם, אלא יכיר רק את שם
המשתמש שלכם, ואת הפרטים שתבחרו לחשוף בפניו בשיחות השונות איתו.
</p>
<h2>
תנאים לרישום לשירות:
</h2>
<p>
<strong>--</strong> על מנת להירשם ולעשות שימוש לשירות ווינקי, עליך לעמוד בכל התנאים

הבאים: 
</p>
<ul>
  <li>
עליך להיות מעל גיל 18. 
</li>

<li>

 עליך להיות כשיר לבצע פעולות משפטיות ולכרות הסכם עם
ווינקי.
</li>
<li>
 עליך להיות בתחומי מדינת ישראל.
</li>
<li>
 עליך להיות בעל תיבת דואר אלקטרוני תקפה ופעילה ברשת
האינטרנט.
</li>
<li>

 לא ביצעת, לא הורשעת או לא הודית בעבירת מין לפי סימן ה',
פרק י' לחוק העונשין תשל"ז-1977 )להלן: "חוק העונשין"(, או כל עבירה פלילית הכרוכה באלימות; או איום באלימות, או בעבירה של התעללות מינית בקטין/בחסר ישע לפי סעיף 368ג
לחוק העונשין, או עבירה לפי סעיף 214)ב( עד 214)ב3( לחוק. 
</li>
<li>
 לא הוסרת בעבר מהשירותים שלנו, אלא אם קיבלת אישור
מפורש בכתב שלנו ליצור חשבון חדש.
</li>

</ul>


<p>

<strong>--</strong> ווינקי תהיה רשאית למנוע ממך מלפתוח חשבון או לקבל את שירות
ווינקי בכל מקרה שבו יתעורר חשש סביר, לפי שיקול דעתה הבלעדי, כי ביצעת מעשה בלתי חוקי ו/או עברת על הוראות הדין ו/או הפרת תנאי מתנאי שימוש אלו ו/או מסרת בעת ההרשמה ו/או לאחר מכן פרטים שגויים ו/או ביצעת מעשה או מחדל שיש בו כדי לפגוע בווינקי ו/או במי מטעמה ו/או בפעילות התקינה של השירות ו/או בצד ג' כלשהו. אין בחסימה, בהפסקה או בהגבלת השימוש, כאמור לעיל, כדי להטיל על ווינקי אחריות כלשהי ו/או כדי לגרוע
מחובתך לנהוג בכל עת בהתאם לתנאי שימוש אלו.
</p>
<p>
<strong>--</strong> אנו שומרים על זכותנו לבקש הוכחת גיל בכל שלב, באופן שנוכל לוודא
שקטינים מתחת לגיל שמונה עשרה )18( אינם משתמשים בשירותים שלנו.

במקרה שיובא לידיעתנו כי אדם מתחת לגיל שמונה עשרה )18( משתמש בשירותים שלנו, אנו נחסום אדם זה מלהיכנס לשירות ווינקי ו/או לאתר, ונעשה כל מאמץ על מנת למחוק מיידית כל מידע אישי בנוגע לאדם כאמור. הנך רשאי לפנות אלינו לכתובת winkee.dating@gmail.com, אם הינך סבור כי
אספנו מידע אישי ממשתמשים מתחת לגיל שמונה עשרה )18(. 
</p>
<p>

<strong>--</strong> אנו שומרים על זכותנו לבקש בדיקת רקע פלילי, אימות זיהוי או כל בחינה אחרת )כגון: חיפושי ברשיום עברייני מין( בכל שלב, תוך שימוש ברושומות
ציבוריות. במקרה שיובא לידיעתנו כי אדם בעל רקע פלילי משתמש בשירותים שלנו, אנו נחסום אדם זה מלהיכנס לשירות ווינקי ו/או לאתר, ונעשה כל מאמץ על מנת למחוק מיידית כל מידע אישי בנוגע לאדם כאמור. הנך רשאי לפנות אלינו לכתובת winkee.dating@gmail.com, אם הינך סבור כי אספנו מידע אישי ממשתמשים בעלי רקע פלילי.
</p>
<h2>
אופן פתיחת חשבון למנוי
</h2>
<p>
<strong>--</strong> על מנת להשתמש בשירות ווינקי ולקבל מנוי לשירותים של ווינקי,
עליך לפתוח חשבון באמצעות טופס הרישום המופיע באתר, במסגרתו תידרש למסור לנו מידע אישי מסוים לרבות שם מלא, כתובת אימייל, גיל, פרטי התחברות לשירות האינטרנטי של ווינקי ו/או כרטיס האשראי שלך וכיוצא באלה. כמו כן, כחלק מהליך פתיחת החשבון יהיה עליך לאשר כי קראת וכי
אתה מסכים לתנאים אלו.
</p>
<p>
<strong>--</strong> במסגרת פתיחת החשבון, עליך לספק לנו מידע מלא, מדויק ונכון, והנך מסכים
בזאת כי לא תציג שלא כהלכה את זהותך או כל פרט אחר בחשבונך. מסירת פרטים שגויים או התחזות לאחר אסורה בהחלט ומהווה עבירה על פי דין. שימוש בחשבון בנק או כרטיס אשראי שאינו שייך לך או מסירת פרטים שגויים אסורים בהחלט ומהווים עבירה על פי דין. היה וסברנו כי הקמת חשבון באמצעות פרטי זיהוי של אדם אחר, הרי שבכך אתה חושף את עצמך לאחריות
פלילית ו/או אזרחית. 
</p>
<p>
<strong>--</strong> עם הרישום, תקבל סיסמא לשימושך האישי בלבד. מנוי זה הוא שלך
בלבד!שמור על הסיסמה ודאג שלא תופץ הלאה. אין להעביר פרטי התחברות לכל גורם אחר.
</p>
<p>
<strong>--</strong> אינך רשאי להעביר או להמחות את זכויותיך או להאציל את חובותיך בחשבון.
</p>
<p>

<strong>--</strong> הליך הרישום לא יושלם ולא ייחשב כמחייב ללא קבלת אישורה הסופי של
ווינקי.
</p>
<h2>
רכישת מנוי:
</h2>
<p>
<strong>--</strong> שירות הצ'אט והקהילה פתוח למשתמשים רשומים שקיבלו לכך רשות כדין מווינקי )"המנוי"(. דמי המנוי מפורטים באתר האינטרנט שלנו וכוללים
מע"מ. 
</p>
<p>

<strong>--</strong> המנוי שלך יימשך עד לביטולו. המנוי לשירותים הינו חודשי ומתחדש מדי חודש,
אלא אם תבקש לבטל את המנוי. ככל שתבקש לבטל את המנוי, הרי שהמנוי יישאר פעיל בכל אותו חודש קלנדרי ויבוטל בחודש הקלנדרי העוקב.

</p>
<p>
<strong>--</strong> המנוי הינו "עסקה מתמשכת" כהגדרתה בחוק הגנת הצרכן, התשמ"א-1981 )להלן: "חוק הגנת הצרכן"( והוא מחויב בתשלום מראש, בתחילת כל חודש
קלנדרי, החל מהחודש הקלנדרי בו נרכש המנוי לראשונה. 
</p>
<p>
<strong>--</strong> אנו עשויים לשנות את דמי המנוי מעת לעת, בכפוף לכך שנשלח לך הודעה,
לפחות שלושים )30( יום מראש, על כל שינוי בדמי המנוי. שים לב שכל שינוי בדמי המנוי לא ישפיע על תקופת מנוי שכבר שולמה ותיכנס לתוקף בתקופת חידוש המנוי שתחל 30 יום לאחר מתן הודעה כאמור.

</p>
<p>
<strong>--</strong> ככל שהתשלום עבור המנוי לא יתקבל על ידינו, מכל סיבה שהיא )למשל אם
פרטי כרטיס האשראי שלך אינם נכונים או אם כרטיס האשראי שלך בוטל(, ואינך מעדכן את פרטי אמצעי התשלום לפי בקשתנו, אנו שומרים על זכותנו לבטל את המנוי או להשעותו באופן מיידי.
</p>
<p>
<strong>--</strong> ווינקי שומרת לעצמה את הזכות לגבות תשלום נוסף עבור שימוש בשירותים קיימים או חדשים נוספים בעתיד.
</p>
<h2>
ביטול מנוי:
</h2>
<p>
<strong>--</strong> תוכל להודיע לנו על רצונך לבטל את המנוי באמצעות: שליחת דואר אלקטרוני הכולל לפחות שם ומספר טלפון לכתובת winkee.dating@gmail.com ; או דרך
הצ'אט עם צוות ווינקי )חווית לקוח(.

</p>
<p>
<strong>--</strong> הודעת הביטול תיכנס לתוקפה עד שלושה ימי עסקים לאחר קבלת ההודעה
אצל ווינקי. 

</p>
<p>
<strong>--</strong> שים לב כי לא ניתן להקפיא את המנוי אלא לבטלו בלבד, וכי למעט כפי
שנדרש מכוח תקנות הגנת הצרכן )ביטול עסקה(, התשע"א-2010 וחוק הגנת הצרכן, לא יוענקו החזרים בגין תשלומים שבוצעו על ידך תחת תנאים אלה, לרבות דמי המנוי כאמור.
</p>
<p>
<strong>--</strong> הביטול מתייחס למנוי המתחדש מדי חודש, ושולמו דמי מנוי עבור החודש בו
מבוקש הביטול, לא תהא זכאי להחזר בגין החודש הנוכחי בו בוצע הודעת הביטול. הביטול התייחס לחודש העוקב מיום הודעת הביטול.
</p>
<p>
<strong>--</strong> שים לב: ככל שתבחר למחוק את חשבונך, המידע והנתונים אודותיך, לרבות
המידע האישי אודותיך, אשר נצברו במהלך שימושך בשירות ווינקי, יימחקו בהתאם לאמור במדיניות הפרטיות שלנו. ווינקי לא תישא בכל אחריות בנוגע לאבדן זה או בנוגע לכל מידע שהוזן ו/או נאסף במהלך שימושך בשירות ווינקי ו/או האתר. זוהי אחריותך הבלעדית לוודא כי מידע זה מגובה וכי אינך
מסתמך עליו לאחר סיום השימוש בשירותים. 
</p>
<p>
<strong>--</strong> אי ציות לתנאים יסיים את השימוש שלך בשירות ווינקי ו/או האתר ואת
תנאים אלו. במקרה של אי-יכולתך לעמוד בתנאים, ווינקי יכולה מיידית, באופן זמני או קבוע להגביל, להשעות או לסגור את חשבונך.
</p>
<p>
<strong>--</strong> אם אתה מתנגד לכל תנאי בתנאי שימוש אלו, כפי שיתוקנו מעת לעת, או אינך
מרוצה מהשירות, אתה רשאי לסיים את ההתקשרות עמנו בכל עת על ידי יציאה מהאתר או סגירת חשבונך בשירות ווינקי ו/או באמצעות שליחת הודעת דואר אלקטרוני לכתובת winkee.dating@gmail.com וזה יהיה הסעד היחיד שיעמוד לרשותך בנסיבות אלו. בנסיבות אלו או בסיום ההתקשרות לפי התנאים בעקבות כישלון לעמוד בתנאים, כל הזכויות המוקנות לך לעיל יסתיימו באופן
אוטומטי ותחויב להפסיק לאלתר כל שימוש בשירות ווינקי ו/או האתר.
</p>
<h2>
אופן ביצוע התשלום בגין המנוי:
</h2>
<p>
<strong>--</strong> תשלומים עבור המנוי מעובדים באמצעות ספקי שירותי תשלום מקוונים )כדוגמת PayPal(.
</p>
<p>
<strong>--</strong> אנו רשאים להוסיף או לשנות את ספקי שירותי התשלום עמם אנו מתקשרים לצורך הענקת השירותים לפי שיקול דעתנו הבלעדי.
</p>
<p>
<strong>--</strong> ספקי שירותי התשלום מאפשרים לך לשלוח תשלומים באופן מקוון ומאובטח באמצעות כרטיס אשראי, כרטיס חיוב או חשבון בנק.
</p>
<p>
<strong>--</strong> אנחנו לא שולטים ואיננו קשורים עם ספקי שירותי התשלום, שהם קבלנים
עצמאיים ואין להם כל יחסי עובד-מעביד או יחסי סוכנות עם ווינקי. ווינקי אינה אחראית בשום אופן למעשים )או מחדלים( של ספקי שירותי התשלום.
</p>
<p>
<strong>--</strong> השימוש בספקי שירותי התשלום הוא על אחריותך בלבד.
</p>
<p>
<strong>--</strong> באחריותך לציית לכל התנאים המפורטים על ידי ספקי שירותי התשלום בתנאי
השימוש ומדיניות הפרטיות שלהם.
</p>
<h2>
הגבלת אחריות:
</h2>

<p>
פיתחנו את המערכת)ווינקי( שלנו כי אנחנו נחושים לעזור למשתמשי ווינקי באינטראקציות חברתיות ובין-אישיות, בהתבסס על יכולות הai החיצוני של google ושל openai , ועל הניסיון המצטבר של משתמשי ווינקי שיכולים לענות בשירות ה׳קהילה׳. המערכות והאנשים העונים לך בשירות הקהילה שלנו אינם משמשים בתור פסיכולוגים, אינם מטפלים, אינם מרפאים ואינם בעלי הכשרה מקצועית או רישיון מקצועי ממשלתי או אחר לטיפול או
ייעוץ כלשהו. 
</p>
<p>
<strong>--</strong> השירותים שמספקת ווינקי הינם שירותי תמיכה והמלצות בלבד, ואתה
אחראי לשימוש שאתה עושה בהמלצות ובמידע שתקבל מווינקי. ואתה מודע לכך שהמידע שאתה מכניס למערכת שלנו נשלח לספקי שירות חיצוני כגון openai וgoogle.
</p>
<p>
<strong>--</strong> שירות הצאט והקהילה מבוסס, בין היתר, על מידע אודות שיחותיך האישיות ומצבך האישי המתקבל על ידך ועל ידי תיאור הסיטואציה בה אתה נמצא
ושעליה אתה מדווח בצ'אט עם הai או עם משתמשי ווינקי אחרים ב׳קהילה׳.
</p>
<p>
<strong>--</strong> השימוש בהמלצות ובמידע שתקבל מווינקי הינו על אחריותך בלבד,
וההחלטה שלך לפעול על פי מידע והמלצות כאמור, הינה החלטה שלך בלבד. אנו ממליצים כי השימוש בשירות ווינקי ובאתר ייעשה תוך הפעלת שיקול דעת, בכל עת. לווינקי אין כל אחריות על שימושך בהודעות ובתשובות
המשתמשים והai המתקבלות כחלק משירותינו.
</p>
<p>
<strong>--</strong> למרות שווינקי שואפת לעודד חווית משתמש מכבדת והתנהלות הקשרים
בסיטואציה המתוארת בשירות תהא הגונה ומכבדת היא אינה אחראית להתנהלות של משתמש כלשהו בשירות או מחוצה לו. אתה מסכים לנקוט בזהירות בכל סיטואציות חברתיות בעולם הדייטינג.
</p>
<p>
<strong>--</strong> מכיוון שווינקי מספקת תמיכה והמלצות בלבד, ווינקי, המערכת של
ווינקי ו/או הנציגים של ווינקי לא יהיו אחראיים בשום צורה להצלחה ו/או לאי-הצלחה בסיטואציות חברתיות ו/או בין-אישיות כלשהן. ווינקי אינה נותנת ערבויות לגבי התנהלות ו/או הצלחה בסיטואציה המוצגת על ידך בשירותים שלנו, בכל עת. וכן, היכולת או הרצון של צד שלישי לתקשר איתך או לפגוש אותך, או התאימות האולטימטיבית עם ו/או התנהלות של צד שלישי
שאתה פוגש ומשתמש בשירותים שלנו במצבך האישי שהצגת לנו.
</p>
<p>
<strong>--</strong> שירותי ווינקי, האתר והתכנים ניתנים לך "כפי שהם" ו"כפי שהם זמינים"
)“AS-IS”(. אתה מסכים ומאשר כי השימוש בשירות ווינקי, באתר ובתכנים )לרבות הסתמכותך על ייעוץ, ומידע הנמסר לך במהלך שימושך בשירות ווינקי( הינו באחריותך הבלעדית, וההחלטות אשר נלקחות במהלך שימושך
בשירות, נלקחות על ידיך ובאחריותך הבלעדית. 
</p>
<p>
<strong>--</strong> ווינקי, לרבות ספקיה, מנהליה, מחזיקי המניות שלה, דירקטורים, קבלני
המשנה שלה, מפיצים, עובדים, גורמים קשורים, סוכנים, נותני הרישיון שלה
ושלוחיה )להלן, ביחד: "נציגי החברה"(, אינם נושאים בכל אחריות או התחייבות מכל סוג שהוא )באופן מפורש או מכללא(, לרבות אחריות לזכות בעלות או לאי הפרה או אחריות מכללא לאיכות מסחרית או להתאמה למטרה מסוימת, וכן אחריות הנובעת ממהלך העסקים הרגיל או פעולה מסחרית, ואין להם כל אחריות לתוצאות העשויות לנבוע משימוש בשירותי ווינקי, באתר ובתכנים, לרבות אחריות בנוגע לתפקוד השירותים ו/או האתר, לאספקת שירותים כאלה או אחרים, למהירות ואמינות השירותים ו/או לתוצאות השימוש בהם. ייתכן כי מוקנות לך זכויות צרכניות נוספות אשר אינן ניתנות לשינוי
באמצעות תנאי שימוש אלה. 
</p>
<p>

<strong>--</strong> בכל מקרה, ווינקי ו/או נציגי החברה, או מערכות החברה לא יישאו בכל
אחריות לכל נזק, לרבות נזק ישיר, גוף, תוצאתי, מיוחד, עקיף ו/או אקראי, שייגרם לך או לצדדים שלישיים עקב השימוש שלך בשירות ווינקי ו/או באתר, במישרין או בעקיפין, כל הפסד או נזק אחר הנובע או קשור לשימוש בשירות על ידך, אף אם נעשו בהתאם להמלצות שניתנו לך במסגרת שירות ווינקי , בכל עילת תביעה שהיא )חוזית, נזיקית או אחרת(, והכל גם במקרה
שבו ווינקי הוזהרה בדבר האפשרות כי עלול להיגרם נזק מסוג זה.
</p>
<p>
<strong>--</strong> מבלי לגרוע מהאמור לעיל, האחריות הכוללת של ווינקי כלפיך לכל נזקיך
מכל סוג שהוא, לא תעלה על המחיר ששולם על ידך ווינקי בפועל בקשר לשירות ווינקי בשנים עשר )12( החודשים שקדמו לקרות הנזק הנטען.
</p>
<p>
<strong>--</strong> לווינקי ו/או נציגי החברה לא תהא כל אחריות ביחס לטעויות או לאי דיוקים שנפלו בכל מידע המופיע באתר או בכל חלק משירות ווינקי.
</p>
<p>
<strong>--</strong> היה וסמכויות שיפוט מסוימות אינן מאפשרות החרגות או הגבלות כאמור לעיל, ההחרגות וההגבלות הנזכרות כאן לא תחולנה במלואן, אלא רק במידה
המקסימאלית המותרת על פי הדין החל.
</p>
<h2>
שיפוי:
</h2>
<p>
<strong>--</strong> יהיה עליך להגן עלינו ולשפות את ווינקי ואת נציגי החברה מפני ונגד כל
תביעה, נזק, הפסד, התחייבות, אחריות, הוצאה, עלויות וחוב )לרבות, ללא מגבלה, שכר טרחת עורכי דין( אשר נובעים מ: )א( שימושך בשירות ווינקי ו/או בתכנים שלא בהתאם לתנאים; )ב( כל הפרה מצדך של תנאים אלו; )ג( הפרה מצדך של כל זכות של או כלפי צד שלישי, לרבות אך לא רק, זכויות קניין רוחני והזכות לפרטיות; ו-)ד( כל נזק מכל סוג שהוא, בין אם נזק ישיר, עקיף, מיוחד או תוצאתי, שגרמת לצד שלישי בקשר עם שימושך בשירות ווינקי ו/או באתר ו/או בתכנים. מובהר בזאת כי חובת השיפוי האמורה תחול
אף לאחר סיום התקשרותך עם ווינקי. 
</p>
<p>
<strong>--</strong> מבלי לגרוע מכלליות האמור לעיל, אנו שומרים את הזכות הבלעדית לנהל את
ההגנה והשליטה הבלעדית בכל עניין והליך הקשור בשיפוי מצדך, באופן שאינו

גורע מחובתך כאמור, והמחייב אותך לשתף פעולה מלאה איתנו בניהול הליך כאמור. אתה מאשר כי לא תסכים לפשרה בכל עניין הכפוף לשיפוי על ידך מבלי לקבל את הסכמתנו לכך מראש ובכתב.
</p>
<h2>
אבטחת מידע ומדיניות פרטיות : 
</h2>
<p>
<strong>--</strong> כל הנתונים והמידע שלך אשר יגיעו לידינו עקב שימושך בשירותים, ישמרו על
ידינו בסודיות ואנו ננקוט באמצעי ותקני האבטחה הרלוונטיים, המקובלים ו/או הנדרשים על פי דין, וזאת על מנת לשמור על שלמות הנתונים והמידע האמור ולהגן עליו מפני חשיפה לכל צד שלישי כלשהו, שימוש זדוני בו או העתקתו
ללא רשות כדין.

</p>
<p>
<strong>--</strong> בהסכמתך לתנאים אלה, הינך מסכים לכך שהצ'אט שלנו איתך נעשה
באמצעות תוכנה של צד שלישי, אשר מספק לווינקי שירותי תוכנת צ'אט ai, ולווינקי אין אחריות לתוכנת הספק, והינך מסכים כי באחריותך לציית לכל התנאים המפורטים על ידי ספק שירותי תוכנת הצ'אט בתנאי השימוש ומדיניות הפרטיות שלו, הזמינים בקישורים הבאים: https://openai.com ו-
https://gemini.google.com/app
</p>
<p>
<strong>--</strong> באחריותך לנקוט בכל האמצעים הנדרשים לשם שמירה על סודיותם של
אמצעי הגישה לטלפון הנייד ממנו אתה משתמש בשירותים ואמצעי הגישה לחשבונך במערכת ווינקי, ולוודא שאף גורם שאינו מורשה לצפייה במידע ו/או שאינו מורשה להשתמש בשירות ווינקי בהתאם לתנאי השימוש המפורטים במסמך זה לא יהיה בעל גישה לצפייה ו/או שימוש מכל סוג שהוא
בשירות ווינקי ו/או בנתונים האגורים בהם. 
</p>
<p>

<strong>--</strong> ווינקי לא תישא באחריות או בחבות עבור נזק שייגרם לך בגין גישה בלתי
מורשית, פריצה או חדירת מערך האבטחה או בגין כשל באחסון או גניבה, מחיקה, השחתה, הרס, נזק או אובדן נתונים או מידע )לרבות ביחס לאובדן או נזק שנגרמו כתוצאה משימוש בלתי מורשה במכשיר הנייד שלך(, למעט ככל
שהחברה לא נקטה באמצעי אבטחת המידע הסבירים הנדרשים על פי דין.
</p>
<p>
<strong>--</strong> הנך נדרש לאבטח את המכשיר ממנו אתה משתמש בשירותים ולדווח לנו על
כל חשד לפריצה או לשימוש לרעה בחשבונך. אם הסיסמא הודלפה ומישהו אחר יעשה שימוש בפרטי ההתחברות שלך, הרי שלווינקי לא תהיה כל אחריות לכך. ווינקי לא תהיה אחראית לנזק כלשהו שייגרם לך כתוצאה
מחשיפת צד אחר לפרטיך ו/או לשימוש שצד שלישי כלשהו יעשה בחשבונך.
</p>
<p>
<strong>--</strong> עליך לדווח לנו באופן מידי על כל שימוש בלתי מורשה בחשבונך, ובכלל זה
בקשר לכל פריצת אבטחה למכשיר ממנו אתה משתמש בשירותים.

</p>
<p>
<strong>--</strong> אם הנך סבור כי ניתן לך מידע שאינו מדויק או כי מתבצע שימוש זדוני על ידי צד שלישי במידע שלך, ביכולתך לפנות אלינו ל-winkee.dating@gmail.com ואנו
ננקוט בכל האמצעים העומדים לרשותנו על מנת לסייע לך.
</p>
<p>
<strong>--</strong> אנו מכבדים את הפרטיות שלך ומחויבים להגן על המידע האישי שאתה משתף
עמנו. מדיניות איסוף המידע לסוגיו הינה בהתאם לחוק בישראל.
</p>
<h2>
התכנים שבאתר ובשירות ווינקי
</h2>
<p>
<strong>--</strong> האתר ושירות ווינקי, בין היתר במסגרת האתר הקהילה והצ'אט, עשויים
להכיל תכנים, מסמכים, מלל, קבצים, לוגואים, אייקונים, תמונות, סרטונים, קישורים, מאגרי נתונים, נתונים טכניים, ידע, מפרטים, “look and feel”, אלגוריתמים, ממשקים, GUI, תכונות אינטראקטיביות, גרפיקה ומאפיינים אחרים, לרבות תכנים כאמור שנמסרו לנו מצדדים שלישיים )להלן:
"התכנים"(.
</p>
<p>
<strong>--</strong> כל הזכויות בתכנים במערך היחסים בין ווינקי ובינך, הינן בבעלות
ווינקי. 
</p>
<p>
<strong>--</strong> האתר והשירותים הם בבעלות ווינקי ומוגנים על ידי חוקי זכויות יוצרים וחוקי
קניין רוחני אחרים. 
</p>
<p>
<strong>--</strong> בכפוף לתנאי שימוש אלו, החברה מעניקה לך זכות אישית, הדירה,
בלתי-ייחודית, בלתי-עבירה ואשר לא ניתן להעניק בה רישיונות-משנה להשתמש בשירותי ווינקי ובתכנים. למען הסר ספק, התנאים אינם מקנים לך זכות בקניין הרוחני של החברה, אלא אך ורק זכות שימוש מוגבלת וניתנת לביטול כאמור. אף הוראה בתנאים לא מהווה ויתור על קניינה הרוחני של
ווינקי על-פי כל דין. 
</p>
<p>
<strong>--</strong> כל זכות שלא הוקנתה לך במפורש על פי תנאי שימוש אלו תישמר בידי
ווינקי ונותני הרישיון שלה. שים לב כי ככל שתמסור לנו משוב אודות האתר או השירותים, הנך מעניק לווינקי רישיון לעשות שימוש בכל משוב כאמור, על- פי שיקול דעתה של ווינקי.
</p>
<h2>
שימושים אסורים
</h2>
<p>
<strong>--</strong> ישנן פעולות מסוימות אשר אסורות בעת השימוש באתר ו/או בשירות ווינקי.
אנא קרא הגבלות אלו בעיון. אי עמידה בהגבלות ואיסורים אלו עשויה להביא לידי סיום את השימוש שלך באתר ו/או בשירות ווינקי, לפי שיקול דעתנו הבלעדי, ואף לחשוף אותך לחבות אזרחית ו/או פלילית.

</p>
<p>
<strong>--</strong> אינך רשאי )ואינך רשאי להתיר לכל צד שלישי(, אלא אם הדבר הותר
במפורש על פי תנאי שימוש אלה: )א( להשתמש בשירותים ו/או באתר ו/או לכל מטרה בלתי חוקית, בלתי מוסרית, בלתי-מורשית ו/או אסורה; )ב( להשתמש
בשירותים ו/או באתר ו/או בתכנים למטרות מסחריות או שאינן
פרטיות; )ג( להסיר או להפריד מהתכנים ו/או מהאתר ו/או מדפי
הנחיתה ו/או מהשירותים כל הגבלות וסימנים המציינים זכויות קנייניות של ווינקי או נותני הרישיון שלה, לרבות כל ההודעות
הקנייניות המופיעות בהם )כגון ©, TM או ®(; )ד( להפר ו/או לפגוע בזכויות המשתמשים לפרטיות וזכויות אחרות, או לאסוף
מידע אישי מזהה אודות משתמשים ללא הסכמתם המפורשת, בין
אם באופן ידני או באמצעות שימוש בכל רובוט, עכביש, כל יישום
חיפוש או אחזור, או שימוש באמצעי, בתהליך או בשיטה ידניים או
אוטומטיים אחרים על מנת להיכנס לאתר ולאחזר, לאסוף ו/או לשאוב מידע; )ה( לפגוע או לשבש פעולת האתר ו/או השירותים,
השרתים או הרשתות המאחסנות את האתר ו/או השרתים, או
להפר כל חוק, תקנה, דרישה, נוהל או מדיניות של שרתים או
רשתות כאמור; )ו( להצהיר הצהרות שקריות או להציג מצג שווא
בנוגע לקשר שלך עם כל אדם או גוף, או לציין במפורש או במרומז כי ווינקי משויכת אליך בכל דרך שהיא, מעניקה
חסות, תומכת בך, באתר שלך, בעסקך או בהצהרותיך, או להציג
מידע שקרי או לא מדויק אודות האתר או ווינקי; )ז( לבצע כל
פעולה היוצרת או העלולה ליצור עומס גדול ובלתי סביר על
תשתית האתר ו/או השירותים; )ח( לעקוף את האמצעים בהם
ווינקי משתמשת על מנת למנוע או להגביל את הגישה לאתר ו/או דפי הנחיתה ו/או השירותים; )ט( להעתיק, לתקן, לשנות, להתאים, למסור, להנגיש, לתרגם, להפנות, לבצע הנדסה חוזרת,
להמיר קוד בינארי לקוד פתוח, לעשות דה-קומפילציה, או להפריד כל חלק בתכנים או באתר ו/או דפי הנחיתה או בשירותים או להציג לציבור, ליצור יצירות נגזרות, לבצע, להפיץ או לעשות
שימוש דומה בתכנים; )י( להעתיק, להפיץ, להציג, לעבד, לתת רישיון משנה, לעשות כל שימוש מסחרי, למכור, להשכיר, להעביר, להלוות, לאסוף, לתרגם, ליצור יצירה נגזרת, לערוך הנדסה הופכית, לשלב עם תוכנה אחרת - כל חומר הכפוף לזכויות קנייניות של ווינקי, לרבות הקניין הרוחני )כהגדרתו
של מונח זה לעיל( של ווינקי, בכל אופן או בכל אמצעי; )יא(
לעשות כל שימוש בתכנים בכל אתר אחר או רשת מחשבים, לכל
מטרה, ללא הסכמה של ווינקי בכתב ומראש; )יב( ליצור
סביבת דפדפן, לתחם )frame(, ליצור העתק )mirror( או לבצע in-line linking לכל חלק מהאתר, השירותים והתכנים; )יג( למכור,
לתת רישיון, או לנצל למטרה מסחרית כלשהי כל שימוש או גישה
לאתר ו/או דפי הנחיתה ו/או לשירותים; )יד( ליצור מאגר מידע על-ידי הורדה ואחסון שיטתיים של כל או חלק מהתכנים; )טו( להעביר או להנגיש בכל דרך אחרת, בקשר לאתר, התכנים או השירותים, כל וירוס, "תולעת", סוס טרויאני, באג, רוגלה, נוזקה, או כל קוד מחשב, קובץ או תוכנה אחרים אשר עשויים להזיק, או נועדו להזיק לפעילות של כל חומרה, תוכנה, ציוד תקשורת, קוד או רכיב; ו/או )טז( להשתמש באתר ו/או בתכנים ו/או בשירותים לכל מטרה בלתי מורשית או שאינה הולמת את מטרת השימוש באתר ו/או להפר אי אלו מהתנאים. )טח( העלאה או שיתוף של תוכן שעלול להיחשב פוגעני או להטריד, להרגיז, להביך, להבהיל או לעצבן כל אדם אחר. תוכן מגונה, פרונוגרפי, אלים או עלול לפגוד בכבוד האדם או מכיל ערום או מקדם גזענות או מעודד
שנאה או סקסיזם או לשון הרע.
</p>
<h2>
כללי
</h2>
<p>
<strong>--</strong> ווינקי רשאית, מעת לעת, לעדכן, לשדרג, לשפר ולאבטח את שירות
ווינקי ו/או את האתר, למחוק כל מידע או תוכן משירות ווינקי או מהאתר או לשנות, לתקן, לשפר, ולעשות שינויים אחרים או להפסיק הספקת או מתן מידע, תכנים או תכונות בהם מבלי לתת כל הודעה מוקדמת ולפי שיקול
דעתה הבלעדי. 
</p>
<p>
<strong>--</strong> ווינקי רשאית, בכל עת, לחסום ו/או להגביל באופן קבוע את גישתך
לשירות ווינקי ו/או לאתר ו/או לחסום, לבטל ו/או להגביל גישה לחשבון על פי שיקול דעתה הבלעדי )בנוסף לכל סעד אחר העשוי לעמוד לרשות החברה על פי כל דין(.
</p>
<p>
<strong>--</strong> נציין כי אנו עשויים להשעות את הגישה לשירות ווינקי ו/או האתר אם אנחנו
סבורים, לפי שיקול דעתנו הבלעדי, כי אחד )או יותר( מן האירועים הבאים התרחשו: )א( יש סיכון לביטחון, או פרטיות; )ב( קיים איום על הביטחון או השלמות של הרשת שלנו או השרתים שלנו; )ג( השעיה נדרשה כדי להגן על זכויות, רכוש או בטיחות של ווינקי, משתמשיה או הציבור; )ד( הפרת תנאים אלה; )ה( אם, לפי שיקול דעתנו הבלעדי, נקבע כי הנך מתנהל כעבריין מועד )משתמש אשר קיבל הודעה בעניין פעילות מפרה יותר מפעמיים(; ו/או )ו( אנו
נדרשים על פי דין; )ז( התקבלה תלונה מצד שלישי בנוגע להתנהגותך.
</p>
<p>
<strong>--</strong> בנוסף, ווינקי רשאית בכל עת, על פי שיקול דעתה הבלעדי, להפסיק את
שירות ווינקי ו/או האתר, באופן זמני או לצמיתות, מבלי לתת כל הודעה מוקדמת. הנך מסכים ומאשר כי ווינקי לא תהיה אחראית לאובדן מידע ו/או נזקים כלשהם הנובעים או הקשורים בהחלטתה להפסיק או להשהות את
פעילות שירות ווינקי ו/או האתר.

</p>
<p>
<strong>--</strong> ווינקי רשאית, על פי שיקול דעתה הבלעדי, לשנות את התנאים מעת לעת, ומבקשת ממך לשוב ולבקר בדף זה לעיתים קרובות ככל האפשר.
</p>
<p>
<strong>--</strong> ווינקי תעשה מאמצים סבירים להודיע לך על כל שינוי מהותי בתנאים,
באמצעות הודעה באתר ו/או על ידי משלוח הודעת דואר אלקטרוני לכתובת הדואר האלקטרוני אותה העברת לווינקי במסגרת השימוש בשירותים ו/או על ידי הודעת למספר עבורו ניתן השירות.
</p>
<p>
<strong>--</strong> שינויים מהותיים שיבוצעו על ידי ווינקי בתנאים ייכנסו לתוקף תוך שבעה
)7( ימים ממועד ההודעה כאמור. כל שאר השינויים בתנאים ייכנסו לתוקף בתאריך העדכון האחרון של התנאים, והמשך השימוש שלך בשירות לאחר תאריך העדכון האחרון הנקוב יהווה הסכמה שלך לכך שהשינויים יחייבו אותך.
</p>
<p>
<strong>--</strong> במידה ונצטרך לשנות את התנאים על מנת לעמוד בדרישות הדין, שינויים אלה יכנסו לתוקף באופן מידי או כנדרש על פי דין, וזאת ללא מתן הודעה מוקדמת.
</p>
<p>
<strong>--</strong> תנאי שימוש אלו חל על כל מי שניגש לשירותים שלנו או משתמש בהם, ללא
קשר לסטטוס הרישום או המנוי. הוראות תנאי שימוש אלה, אשר על פי טבען שורדות את סיום ההתקשרות בכדי לקיים את מטרות התנאים יישארו בתוקף. מבלי לגרוע מכלליות האמור לעיל, הסעיפים בדבר קניין רוחני, הגבלת אחריות,
שיפוי ו- כללי ימשיכו לחול אף לאחר סיום ההתקשרות. 
</p>
<p>
<strong>--</strong> תנאי שימוש אלה ממצים את מלוא ההבנות והתנאים שהוסכמו בינך לבין
ווינקי בקשר לנושאים המופיעים בתנאים אלה, והם גוברים על כל הסכמות אחרות, קודמות או תקפות, בכתב או בעל פה, בינך לבין ווינקי.
</p>
<p>
<strong>--</strong> אם הוראה כלשהי בתנאי השימוש תיחשב בלתי חוקית או בלתי ניתנת לאכיפה, הוראה הזאת לא תכול ושאר התנאים ימשיכו להיות בתוקף.
</p>
<p>
<strong>--</strong> אי פעולה של ווינקי במימוש או אכיפת זכות או הוראה כלשהי בתנאי השימוש לא תהווה ויתור על זכות או הוראה כאמור.
</p>
<p>
<strong>--</strong> כל תביעה בקשר לשירות ווינקי ו/או לאתר או לשימוש בהם, תהיה כפופה
לחוקי מדינת ישראל, ותתפרש על-פי חוקים אלה )מבלי ליתן תוקף לכללי ברירת הדין הבינלאומי הקבועים בהם(, ואף לא יחולו עליה אמנת האומות המאוחדות בנוגע לדין האחיד בדבר מכר טובין בינלאומי.
</p>


</p>
</p>
</div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Terms