import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import HorizontalStepperWithError from "./Stepper";

function Turtoial() {
    const { isAuthenticated, user } = useAuth0();
    const navigate = useNavigate();

    return(
        <div className="custom-home-page">
            <div className="hero" style={{alignItems:'center'}}>
                <div className="cool-move" ></div>
        <HorizontalStepperWithError></HorizontalStepperWithError>
</div>
</div>
    )



      
}

export default Turtoial;